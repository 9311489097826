import React, { FC, PropsWithChildren } from "react";
interface SectionContainerImage {
    additionalClassName?: string
    id: string
}

export const SectionContainerImage: FC<PropsWithChildren<SectionContainerImage>> = ({ children, id, additionalClassName }) => {
    let className = `py-20 bg-center bg-aboutus bg-cover`

    className += ` ${additionalClassName ?? ""}`;
    return (
        <section id={id} className={className}>
            {children}
        </section>
    )
}