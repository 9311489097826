import { useRouter } from "next/router";
import React from "react";

export const Bootcamp = () => {
    const router = useRouter();
    /*const [fullStackSchedules, setFullStackSchedules] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(''); 
                const data = await response.json();
                setFullStackSchedules(data);
            } catch (error) {
                console.error('Error fetching data:');
            }
        };

        fetchData();
    }, []);*/

    // Using manual data temporarily, as the API is still pending.
    const FullStackSchedules = [
        {
            id: 1,
            programStart: 'Bulan Maret 2024',
        },
    ];

    return (
        <div>
            <div className="px-10 py-5">
                {FullStackSchedules.map((courseSchedule) => (
                    <div key={courseSchedule.id} className='flex justify-evenly pl-0 sm:pl-1 mb-5'>
                        <div className="flex">
                            <h1 className='text-md md:text-lg lg:text-4xl text-[#0D9488] font-body font-bold mt-5 mb-5'>{courseSchedule.programStart}</h1>
                        </div>
                        <div className="w-1/3 flex items-center justify-center">
                            <button className='hover:bg-white hover:text-accent-100 shrink text-sm sm:text-base font-medium font-body text-white bg-accent-100 py-2 px-4 sm:px-8 sm:py-3 rounded transition duration-300 ease-out' onClick={() => router.push("/courses/register")}>
                                Daftar
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}