import React from 'react';
import { SectionContainerGray } from '../common/sections/SectionContainerGray';
import { HeaderSection } from '../common/headers/HeaderSection';
import { programTypeNew } from '@/constants';
import { ProgramHome } from '../common/badge/BadgeProgramHome';

export const ProgramTypeNew: React.FunctionComponent<{
    className?: string;
}> = () => {
    return (
        <SectionContainerGray id='program-type'>
            <div className="flex flex-wrap">
                <HeaderSection title='Jenis Program' subtitle='Paket Program yang Bisa di Ikuti Peserta' />
                <div className="w-full self-center">
                    <div className='grid-cols-1 place-items-center' >
                        <div className='col-span-1' />
                        <div className="col-span-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 place-items-center place-content-center gap-12 md:gap-24 mx-16">
                            {programTypeNew.map((item) => (
                                <ProgramHome key={item.id} data={item} />
                            ))}
                        </div>
                        <div className='col-span-1' />
                    </div>
                </div>
            </div>
        </SectionContainerGray>
    );
};