import React from "react";
import { SectionContainerImage } from "../common/sections/SectionContainerImage";
import Link from "next/link";

export const SectionAboutOurCompany = () => {
    return (
        <SectionContainerImage id="about-us" >
            <div className="flex flex-wrap px-8 md:px-10">
                <div className='w-full self-center sm:px-4 md:w-1/2 hidden justify-center md:flex order-last md:order-first'>
                    <div className='mt-10'>
                        <img src="img/about-us.png" alt="landing-page.png" className='max-w-full mx-auto' />
                    </div>
                </div>
                <div className="w-full self-center sm:px-4 md:w-1/2">
                    <h2 className='text-2xl md:text-xl lg:text-3xl text-accent-100 font-body font-bold tracking-wider leading-snug md:leading-relaxed mb-7 md:mb-7'>Tentang Perusahaan Kami</h2>
                    <p className='text-base md:text-sm lg:text-lg text-white font-body font-medium mb-7 md:mb-10'>PT Accelist Edukasi Indonesia merupakan bagian dari PT Accelist Lentera Indonesia yang sudah berdiri sejak tahun 2012 juga telah mendukung kemajuan industri digital melalui 7 batch training dan perekrutan karyawan tetap. Kami menyediakan program-program yang dapat diikuti oleh mahasiswa ataupun kalangan lainnya untuk menambah kemampuan ilmu Teknik Informatika sehingga akan melahirkan talenta digital yang berkualitas dan berkompeten untuk memenuhi kebutuhan tenaga kerja IT di Indonesia.</p>
                    <Link href={"/tentang-kami"} className='text-sm sm:text-base font-medium font-body text-white bg-accent-100 py-3 px-4 sm:px-8 sm:py-4 rounded hover:bg-white hover:text-accent-100 transition duration-300 ease-out'>
                        Lihat Detail
                    </Link>
                </div>
            </div>
        </SectionContainerImage>
    )
}