import React from 'react';

export const ButtonGradientColor: React.FC<{
    type?: string
    isLargeSize?: boolean
    label?: string
    onClick?: () => void
}> = ({ type, isLargeSize, label, onClick }) => {
    return (
        <div className={`${type === 'Workshop' ? classNames.borderWorkshop : type === 'Bootcamp' ? classNames.borderBootcamp : classNames.borderFreeCourse} rounded-md py-0.5 px-1 mt-4 mb-10`}>
            <button onClick={onClick} className={`${type === 'Workshop' ? classNames.workshop :  type === 'Bootcamp' ? classNames.bootcamp : classNames.freecourse} ${isLargeSize ? 'py-4 px-10' : 'py-2 px-5'}`}>
                <p className='text-sm text-secondary-100 font-body font-bold'>{label ?? 'Lihat Program'}</p>
            </button>
        </div>
    )
}

const classNames = {
    freecourse: 'hover:bg-gradientbronze-20 transition duration-300 ease-in-out bg-gradient-to-r from-gradientbronze-30 via-gradientbronze-20 to-gradientbronze-15 rounded-md',
    workshop: 'hover:bg-gradientsilver-20 transition duration-300 ease-in-out bg-gradient-to-r from-gradientsilver-30 via-gradientsilver-20 to-gradientsilver-15 rounded-md ',
    bootcamp: 'hover:bg-gradientgold-20 transition duration-300 ease-in-out bg-gradient-to-r from-gradientgold-30 via-gradientgold-20 to-gradientgold-15 rounded-md ',
    borderFreeCourse: 'w-fit bg-gradient-to-r from-gradientbronze-30 via-gradientbronze-20 to-gradientbronze-15',
    borderWorkshop: 'w-fit bg-gradient-to-r from-gradientsilver-30 via-gradientsilver-20 to-gradientsilver-15',
    borderBootcamp: 'w-fit bg-gradient-to-r from-gradientgold-30 via-gradientgold-20 to-gradientgold-15',
}

