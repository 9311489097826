import React, { FC, PropsWithChildren } from "react";
interface SectionContainerLight {
    additionalClassName?: string
    id: string
}

export const SectionContainerLight: FC<PropsWithChildren<SectionContainerLight>> = ({ children, id, additionalClassName }) => {
    let className = 'py-16 sm:py-20 bg-white'

    className += ` ${additionalClassName ?? ""}`;
    return (
        <section id={id} className={className}>
            {children}
        </section>
    )
}