import { useRouter } from "next/router";
import React from "react";
import { OnlineCourseSchedule } from "@/interfaces/OnlineCourseSchedule";

export const Workshop = () => {
    const router = useRouter();

    const videoGameSchedules: OnlineCourseSchedule[] = [
        {
            id: 1,
            typeName: 'Video Game',
            date: '11 dan 12 Januari 2024',
            time: '18.30 - 21.30',
            programType: 'Video Game',
        },
        {
            id: 2,
            date: '18 dan 19 Januari 2024',
            time: '18.30 - 21.30',
            programType: 'Video Game',
        },
        {
            id: 3,
            date: '25 dan 26 Januari 2024',
            time: '18.30 - 21.30',
            programType: 'Video Game',
        },
    ];
    const frontEndSchedules: OnlineCourseSchedule[] = [
        {
            id: 4,
            typeName: 'Front-End',
            date: '11 dan 12 Januari 2024',
            time: '18.30 - 21.30',
            programType: 'Frontend',
        },
        {
            id: 5,
            date: '23 dan 24 Januari 2024',
            time: '18.30 - 21.30',
            programType: 'Frontend',
        },
        {
            id: 6,
            date: '1 dan 5 Februari 2024',
            time: '18.30 - 21.30',
            programType: 'Frontend',
        },
    ];
    const backEndSchedules: OnlineCourseSchedule[] = [
        {
            id: 7,
            typeName: 'Back-End',
            date: '2 dan 8 Januari 2024',
            time: '18.30 - 21.30',
            programType: 'Backend',
        },
        {
            id: 8,
            date: '9 dan 16 Januari 2024',
            time: '18.30 - 21.30',
            programType: 'Backend',
        },
        {
            id: 9,
            date: '15 dan 22 Januari 2024',
            time: '18.30 - 21.30',
            programType: 'Backend',
        },
    ];
    const mobileAppSchedules: OnlineCourseSchedule[] = [
        {
            id: 10,
            typeName: 'Mobile Application',
            date: '3 dan 4 Januari 2024',
            time: '18.30 - 21.30',
            programType: 'Mobile',
        },
        {
            id: 11,
            date: '10 dan 17 Januari 2024',
            time: '18.30 - 21.30',
            programType: 'Mobile',
        },
        {
            id: 12,
            date: '30 dan 31 Januari 2024',
            time: '18.30 - 21.30',
            programType: 'Mobile',
        },
    ];
    return (
        <div>
            <div className="workshop-schedule-web px-10 py-5">
                {
                    videoGameSchedules.map((courseSchedule => {
                        return (
                            <div key={courseSchedule.id} className='flex pl-0 sm:pl-1 mb-5'>
                                <div className="basis-1/4">
                                    <h1 className='basis-1/4 text-md md:text-lg lg:text-2xl text-white font-body font-bold tracking-wide mt-5 mb-5'>{courseSchedule.typeName}</h1>
                                </div>
                                <div className="w-2/4 flex flex-col items-center justify-center">
                                    <h4 className='text-md md:text-xl text-white font-body font-bold tracking-wide text-center'>{courseSchedule.date}</h4>
                                    <div className='w-fit items-center justify-center bg-main-100 rounded-full ml-5 px-2 py-1 mt-2 md:px-4 sm:py-2'>
                                        <p className='text-secondary-100 text-[9.5px] md:text-base font-body font-bold'>{courseSchedule.time}</p>
                                    </div>
                                </div>
                                <div className="w-1/3 flex items-center justify-center">
                                    <button className='hover:bg-white hover:text-accent-100 shrink text-sm sm:text-base font-medium font-body text-white bg-accent-100 py-2 px-4 sm:px-8 sm:py-3 rounded transition duration-300 ease-out' onClick={() => router.push("/courses/register")}>
                                        Daftar
                                    </button>
                                </div>
                            </div>
                        );
                    }))
                }
                {
                    frontEndSchedules.map((courseSchedule => {
                        return (
                            <div key={courseSchedule.id} className='flex pl-0 sm:pl-1 mb-5'>
                                <div className="basis-1/4">
                                    <h1 className='basis-1/4 text-md md:text-lg lg:text-2xl text-white font-body font-bold tracking-wide mt-5 mb-5'>{courseSchedule.typeName}</h1>
                                </div>
                                <div className="w-2/4 flex flex-col items-center justify-center">
                                    <h4 className='text-md md:text-xl text-white font-body font-bold tracking-wide text-center'>{courseSchedule.date}</h4>
                                    <div className='w-fit items-center justify-center bg-main-100 rounded-full ml-5 px-2 py-1 mt-2 md:px-4 sm:py-2'>
                                        <p className='text-secondary-100 text-[9.5px] md:text-base font-body font-bold'>{courseSchedule.time}</p>
                                    </div>
                                </div>
                                <div className="w-1/3 flex items-center justify-center">
                                    <button className='hover:bg-white hover:text-accent-100 shrink text-sm sm:text-base font-medium font-body text-white bg-accent-100 py-2 px-4 sm:px-8 sm:py-3 rounded transition duration-300 ease-out' onClick={() => router.push("/courses/register")}>
                                        Daftar
                                    </button>
                                </div>
                            </div>
                        );
                    }))
                }
                {
                    backEndSchedules.map((courseSchedule => {
                        return (
                            <div key={courseSchedule.id} className='flex pl-0 sm:pl-1 mb-5'>
                                <div className="basis-1/4">
                                    <h1 className='basis-1/4 text-md md:text-lg lg:text-2xl text-white font-body font-bold tracking-wide mt-5 mb-5'>{courseSchedule.typeName}</h1>
                                </div>
                                <div className="w-2/4 flex flex-col items-center justify-center">
                                    <h4 className='text-md md:text-xl text-white font-body font-bold tracking-wide text-center'>{courseSchedule.date}</h4>
                                    <div className='w-fit items-center justify-center bg-main-100 rounded-full ml-5 px-2 py-1 mt-2 md:px-4 sm:py-2'>
                                        <p className='text-secondary-100 text-[9.5px] md:text-base font-body font-bold'>{courseSchedule.time}</p>
                                    </div>
                                </div>
                                <div className="w-1/3 flex items-center justify-center">
                                    <button className='hover:bg-white hover:text-accent-100 shrink text-sm sm:text-base font-medium font-body text-white bg-accent-100 py-2 px-4 sm:px-8 sm:py-3 rounded transition duration-300 ease-out' onClick={() => router.push("/courses/register")}>
                                        Daftar
                                    </button>
                                </div>
                            </div>
                        );
                    }))
                }
                {
                    mobileAppSchedules.map((courseSchedule => {
                        return (
                            <div key={courseSchedule.id} className='flex pl-0 sm:pl-1 mb-5'>
                                <div className="basis-1/4">
                                    <h1 className='basis-1/4 text-md md:text-lg lg:text-2xl text-white font-body font-bold tracking-wide mt-5 mb-5'>{courseSchedule.typeName}</h1>
                                </div>
                                <div className="w-2/4 flex flex-col items-center justify-center">
                                    <h4 className='text-md md:text-xl text-white font-body font-bold tracking-wide text-center'>{courseSchedule.date}</h4>
                                    <div className='w-fit items-center justify-center bg-main-100 rounded-full ml-5 px-2 py-1 mt-2 md:px-4 sm:py-2'>
                                        <p className='text-secondary-100 text-[9.5px] md:text-base font-body font-bold'>{courseSchedule.time}</p>
                                    </div>
                                </div>
                                <div className="w-1/3 flex items-center justify-center">
                                    <button className='hover:bg-white hover:text-accent-100 shrink text-sm sm:text-base font-medium font-body text-white bg-accent-100 py-2 px-4 sm:px-8 sm:py-3 rounded transition duration-300 ease-out' onClick={() => router.push("/courses/register")}>
                                        Daftar
                                    </button>
                                </div>
                            </div>
                        );
                    }))
                }
            </div>

            <div className='workshop-schedule-mobile pb-5 w-full'>
                <p className='text-2xl text-white font-body font-bold tracking-wide mt-10 -mb-4 text-center'>Video Game</p>
                {
                    videoGameSchedules.map((courseSchedule => {
                        return (
                            <div key={courseSchedule.id} className="text-center items-center my-10">
                                <p className='text-white text-xl font-body font-semibold my-3'>{courseSchedule.date}</p>
                                <p className='text-white font-body font-bold mb-5'>
                                    <span className="text-secondary-100 text-[15px] bg-main-100 rounded-xl py-2 px-4">
                                        {courseSchedule.time}
                                    </span>
                                </p>
                                <button className='hover:bg-white hover:text-accent-100 text-lg sm:text-base font-medium font-body text-white bg-accent-100 py-2 px-9 rounded transition duration-300 ease-out' onClick={() => router.push("/courses/register")}>
                                    Daftar
                                </button>
                            </div>
                        );
                    }))
                }
                <p className='text-2xl text-white font-body font-bold tracking-wide mt-10 -mb-4 text-center'>Front-End Website</p>
                {
                    frontEndSchedules.map((courseSchedule => {
                        return (
                            <div key={courseSchedule.id} className="text-center items-center my-10">
                                <p className='text-white text-xl font-body font-semibold my-3'>{courseSchedule.date}</p>
                                <p className='text-white font-body font-bold mb-5'>
                                    <span className="text-secondary-100 text-[15px] bg-main-100 rounded-xl py-2 px-4">
                                        {courseSchedule.time}
                                    </span>
                                </p>
                                <button className='hover:bg-white hover:text-accent-100 text-lg sm:text-base font-medium font-body text-white bg-accent-100 py-2 px-9 rounded transition duration-300 ease-out' onClick={() => router.push("/courses/register")}>
                                    Daftar
                                </button>
                            </div>
                        );
                    }))
                }
                <p className='text-2xl text-white font-body font-bold tracking-wide mt-10 -mb-4 text-center'>Back-End Website</p>
                {
                    backEndSchedules.map((courseSchedule => {
                        return (
                            <div key={courseSchedule.id} className="text-center items-center my-10">
                                <p className='text-white text-xl font-body font-semibold my-3'>{courseSchedule.date}</p>
                                <p className='text-white font-body font-bold mb-5'>
                                    <span className="text-secondary-100 text-[15px] bg-main-100 rounded-xl py-2 px-4">
                                        {courseSchedule.time}
                                    </span>
                                </p>
                                <button className='hover:bg-white hover:text-accent-100 text-lg sm:text-base font-medium font-body text-white bg-accent-100 py-2 px-9 rounded transition duration-300 ease-out' onClick={() => router.push("/courses/register")}>
                                    Daftar
                                </button>
                            </div>
                        );
                    }))
                }
                <p className='text-2xl text-white font-body font-bold tracking-wide mt-10 -mb-4 text-center'>Mobile Application</p>
                {
                    mobileAppSchedules.map((courseSchedule => {
                        return (
                            <div key={courseSchedule.id} className="text-center items-center my-10">
                                <p className='text-white text-xl font-body font-semibold my-3'>{courseSchedule.date}</p>
                                <p className='text-white font-body font-bold mb-5'>
                                    <span className="text-secondary-100 text-[15px] bg-main-100 rounded-xl py-2 px-4">{courseSchedule.time}</span>
                                </p>
                                <button className='hover:bg-white hover:text-accent-100 text-lg sm:text-base font-medium font-body text-white bg-accent-100 py-2 px-9 rounded transition duration-300 ease-out' onClick={() => router.push("/courses/register")}>
                                    Daftar
                                </button>
                            </div>
                        );
                    }))
                }
            </div>
        </div>
    )
}