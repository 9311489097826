import React, { FC } from 'react'

interface CircleLanding {
    additionalClassName?: string
    numberData: string
    subtitle: string
}

export const CircleLanding: FC<CircleLanding> = ({ numberData, subtitle, additionalClassName }) => {
    return (
        <div className={additionalClassName}>
            <div className='flex flex-col justify-center items-center w-32 h-32 md:w-40 md:h-40 sm:w-40 sm:h-40 rounded-full bg-main-80 shadow-[3px_7px_10px_0px_#0000000f]'>
                <h2 className='text-secondary-100 text-2xl sm:text-3xl font-body font-bold'>{numberData}</h2>
                <p className='text-secondary-50 text-md sm:text-base font-body font-regular mt-1 sm:mt-2 px-2 text-center'>{subtitle}</p>
            </div>
        </div>
    )
}
