import React from 'react';
import { SectionContainerLight } from '../common/sections/SectionContainerLight';
import { holdingPartnersImageSrc } from '@/constants';
import { HeaderSection } from '../common/headers/HeaderSection';

export const SectionOurHoldingPartners: React.FunctionComponent<{
    className?: string;
}> = () => {

    return (
        <SectionContainerLight id='landing' >
            <HeaderSection title='Our Holding Partners' subtitle='Partner Kami yang Terpercaya' />
            <div className="px-7 md:px-10 lg:px-20 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-20 gap-x-10 md:gap-x-4">
                {holdingPartnersImageSrc.map((src, index) => {
                    return (
                        <div key={index.toString()} className='flex items-center justify-center'>
                            <img src={src} alt="image.png" />
                        </div>
                    )
                })}
            </div>
        </SectionContainerLight>
    );
};
