import { SectionLanding } from '@/components/home/SectionLanding';
import { WithDefaultLayout } from '../components/DefautLayout';
import { Title } from '../components/Title';
import { Page } from '../types/Page';
import { SectionFaq } from '@/components/home/SectionFaq';
import { SectionAboutOurCompany } from '@/components/home/SectionAboutOurCompany';
import { SectionRegistrationProcedure } from '@/components/home/SectionRegistrationProcedure';
import { SectionWhyStudyInAccelist } from '@/components/home/SectionWhyStudyInAccelist';
import { SectionOurHoldingPartners } from '@/components/home/SectionOurHoldingPartners';
import { SectionTestimony } from '@/components/home/SectionTestimony';
import { SectionDocumentation } from '@/components/home/SectionDocumentation';
import { ProgramTypeNew } from '@/components/home/ProgramTypeNew';
import {ScheduleProgramNew } from '@/components/home/ScheduleProgramNew';

const IndexPage: Page = () => {
    return (
        <div>
            <Title title={'Home'} description={'Belajar coding di Accelist Edukasi Indonesia dengan jadwal fleksibel, murah, dan berkualitas. Yuk daftar sekarang!'} />
            <SectionLanding />
            <SectionAboutOurCompany />
            <ProgramTypeNew/>
            <ScheduleProgramNew/>
            <SectionRegistrationProcedure />
            <SectionWhyStudyInAccelist />
            <SectionOurHoldingPartners />
            <SectionDocumentation />
            <SectionTestimony />
            <SectionFaq />
        </div>
    );
};

IndexPage.layout = WithDefaultLayout;
export default IndexPage;
