import React, { useState } from 'react'
import { HeaderSection } from '../common/headers/HeaderSection'
import { documentations } from '@/constants';
import { ImageModal } from '../common/modal/ModalImage';
import { SectionContainerGray } from '../common/sections/SectionContainerGray';

export const SectionDocumentation = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedImageUri, setSelectedImageUri] = useState('');

    const showModal = (imageUri: string) => {
        setIsModalVisible(true);
        setSelectedImageUri(imageUri)
    };

    const hideModal = () => {
        setIsModalVisible(false);
        setSelectedImageUri('')
    };
    return (
        <SectionContainerGray id='documentations' >
            <HeaderSection title='Dokumentasi' subtitle='Kegiatan Alumni yang Sudah Mendaftar di Accelist Edukasi Indonesia' />
            <div className='grid grid-cols-1 place-items-center px-4 sm:px-14'>
                <div className="grid grid-cols-2 xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-center place-content-center gap-8 md:gap-16">
                    {documentations.map((e, i) => {
                        return (
                            <div key={i.toString()} className='w-full h-full flex flex-col items-center justify-center' >
                                <div onClick={() => showModal(e.src)} className='w-52 h-44 sm:w-64 sm:h-56 md:w-72 md:h-64 lg:w-72 lg:h-64 2xl:w-96 2xl:h-80 bg-secondary-30 rounded-xl cursor-pointer'>
                                    <img src={e.src} alt={e.src} className={documentationsImageClassesStyle(i)} />
                                </div>
                                <p className='w-10/12 xs:w-7/12 text-sm sm:text-md md:text-base text-secondary-100 text-center font-body font-bold mt-3 xs:mt-1.5'>{e.description}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <ImageModal imageUrl={selectedImageUri} hideModal={hideModal} isModalVisible={isModalVisible} />
        </SectionContainerGray>
    )
}

const documentationsImageClassesStyle = (index: number) => {
    if (index > 2) {
        return 'object-cover w-full h-full rounded-xl object-right';
    } else {
        return 'object-cover w-full h-full rounded-xl';
    }

}