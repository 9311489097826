import React, { useEffect, useState } from 'react'
import { HeaderSection } from '../common/headers/HeaderSection'
import { testimony } from '@/constants';
import { Testimony } from '@/interfaces/Testimony';
import { SectionContainerLight } from '../common/sections/SectionContainerLight';

export const SectionTestimony = () => {
    const [selectedId, setSelectedId] = useState(1);
    const [selectedData, setSelectedData] = useState(testimony.find(e => e.id === selectedId))
    const [dataTetimony, setDataTestimony] = useState<Testimony[]>([]);

    useEffect(() => {
        const filteredData = testimony.filter(item => item.id !== selectedId);
        setSelectedData(testimony.find(e => e.id === selectedId))
        setDataTestimony(filteredData)
    }, [selectedId])

    return (
        <SectionContainerLight id='testimony'>
            <HeaderSection title='Apa Kata Mereka' subtitle='Peserta yang Puas Dengan Accelist Edukasi Indonesia' />
            <div className="grid grid-cols-1 xl:grid-cols-12 gap-y-7 px-8 md:px-28">
                <div className='grid grid-cols-1 col-span-6 gap-y-5 sm:grid-cols-2 place-items-center bg-main-100 p-8 sm:p-8 rounded-xl shadow-[4px_4px_6px_0px_#0000000F]'>
                    <img src={selectedData?.imageSrc} alt="landing-page.png" className='max-h-96 max-w-64 bg-contain rounded-xl' />
                    <div className='ml-0 sm:ml-5'>
                        <h2 className='text-3xl text-secondary-100 font-body font-bold mb-2 sm:mb-4'>{selectedData?.name}</h2>
                        <h5 className='text-lg text-accent-100 font-body font-medium mb-4 sm:mb-7'>{`Alumni batch ${selectedData?.batch}`}</h5>
                        <p className='text-sm text-secondary-100 font-body font-normal'>{selectedData?.tetimony}</p>
                    </div>
                </div>
                <div className='order-1' />
                <div className="grid grid-cols-2 xl:order-2 col-span-5 place-items-center gap-6 lg:mt-0">
                    {dataTetimony?.map((item, index) => {
                        return (
                            <div onClick={() => setSelectedId(item.id)} key={index.toString()} className='p-4 w-[153px] h-[187px] sm:w-[213px] sm:h-[247px] bg-main-100 rounded-xl cursor-pointer shadow-[4px_4px_6px_0px_#0000000F]'>
                                <img src={item.imageSrc} alt="iamge.png" className='rounded-xl object-cover w-[121px] h-[151px] sm:w-[181px] sm:h-[211px]' />
                            </div>
                        )
                    })}
                </div>
            </div>
        </SectionContainerLight>
    )
}