import React, { FC } from 'react'

interface SectionHeaderProps {
    title: string
    subtitle: string
    darkMode?: boolean
}

export const HeaderSection: FC<SectionHeaderProps> = ({ title, subtitle, darkMode }) => {
    return (
        <div className="flex flex-col mx-auto text-center mb-8 md:mb-16 items-center">
            <h2 className={`${darkMode ? 'text-white' : 'text-secondary-100 '} text-2xl font-body font-bold align-center sm:text-4xl leading-relaxed`}>{title}</h2>
            <p className="text-secondary-60 text-base font-body font-light align-center mt-1 md:mt-3 sm:text-lg px-3">{subtitle}</p>
            <div className='border-2 md:border-4 border-accent-100 rounded-full w-40 mt-3 md:mt-5' />
        </div>
    )
}