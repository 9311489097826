import React, { FC } from 'react';
import { Modal } from 'antd';

interface ImageModal {
    imageUrl: string, isModalVisible: boolean, hideModal: () => void
}

export const ImageModal: FC<ImageModal> = ({ imageUrl, isModalVisible, hideModal }) => {


    return (
        <>
            <Modal open={isModalVisible} width={1200} centered={true} onCancel={hideModal} footer={null}>
                <img className='rounded-xl' src={imageUrl} alt="Image" />
            </Modal>
        </>
    );
};