import React, { FC } from 'react'

interface CardRegistrationProcedurProps {
    title: string
    description: string
    order: string
}

export const CardRegistrationProcedur: FC<CardRegistrationProcedurProps> = ({ order, title, description }) => {
    return (
        <div className='relative w-[299px] h-[262px] shadow-[4px_4px_6px_0px_#0D948838] px-6 text-center mt-16'>
            <div className='flex absolute mx-auto -top-10 right-0 left-0 bg-accent-100 w-[76px] h-[76px] justify-center items-center rounded-full text-2xl text-white font-body font-bold border-main-100 border-8'>
                {order}
            </div>
            <h5 className='mt-16 text-secondary-100 text-xl font-body font-bold'>{title}</h5>
            <p className='mt-5 text-secondary-100 text-sm font-body font-normal'>{description}</p>
        </div>
    )
}
