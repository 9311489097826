import React from "react";
import { BadgeCircle } from "./BadgeCircleV2";
import { ProgramTypeV2Properties } from "@/interfaces/ProgramType";
import { ButtonGradientColor } from "../buttons/ButtonGradientColor";
import Link from "next/link";
import Router from "next/router";

export const ProgramHome: React.FunctionComponent<{
    className?: string;
    data: ProgramTypeV2Properties;
}> = (props) => {
    const { data } = props;

    const programDetailsLink = (id) => {
        switch (id) {
            case 1:
                return '/program/free-course/video-game';
            case 2:
                return '/program/free-course/front-end-website';
            case 3:
                return '/program/free-course/back-end-website';
            case 4:
                return '/program/free-course/mobile-application';
            case 5:
                return '/program/workshop/video-game';
            case 6:
                return '/program/workshop/front-end-website';
            case 7:
                return '/program/workshop/back-end-website';
            case 8:
                return '/program/workshop/mobile-application';
            case 9:
                return '/program/bootcamp/full-stack-engineer';
            default:
                return '/';
        }
    };

    return (
        <div className={containerClassStyle(data.typeCourse)}>
            {(data.typeCourse !== 'Bootcamp') && (
                <BadgeCircle data={data} size={90} />
            )}
            <div className={`${data.typeCourse === 'Bootcamp' ? 'bg-[#1e293b] rounded-lg m-2 flex justify-center items-center flex-col relative' : 'badge relative'}`} style={{ height: '440px' }}>
                <p className={typeCourseTextClassStyles(data.typeCourse)} >{data.typeCourse}</p>
                <p className={typeCourseDescriptionTextClassStyles(data.typeCourse)} >{data.typeCourseDescription}</p>
                <p className={stackTextClassStyles(data.typeCourse)} >{data.stack}</p>
                {(data.typeCourse !== 'Free Course') && (
                    <div className='relative top-0 left-6 mr-40'>
                       <span style={{ color: data.typeCourse === 'Workshop' ? '#CDCDCD' : '#FEEC9B' }} className="text-sm font-semibold">Hanya</span>
                    </div>
                )}
                <p className={totalPriceTextClassStyles(data.typeCourse)} >{data.totalPrice}</p>
                <p className={packageDescriptionTextClassStyles(data.typeCourse)} >{data.packageDescription}</p>
                <p className={packageSubdescriptionTextClassStyles(data.typeCourse)} >{data.packageSubdescription}</p>
                <ButtonGradientColor onClick={() => Router.push(programDetailsLink(data.id))} type={data.typeCourse} />

                <div className={buttonBorderClassStyles(data.typeCourse)}>
                    <Link href="/courses/register" className={buttonRegisterClassStyles(data.typeCourse)}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30px' }}>
                            <p className='text-sm text-secondary-100 font-body font-bold'>Daftar Sekarang</p>
                        </div>
                    </Link>
                </div>

            </div>
        </div>
    );
};

const classes = {
    backgroundFreeCourse: 'container-badge relative rounded-xl bg-gradient-to-r from-gradientbronze-100 via-gradientbronze-90 to-gradientbronze-80',
    backgroundWorkshop: 'container-badge relative rounded-xl bg-gradient-to-r from-gradientsilver-100 via-gradientsilver-90 to-gradientsilver-80',
    backgroundBootcamp: 'w-96 relative transition duration-700 ease-in-out bg-blue-500 transform hover:-translate-y-3 hover:scale-100 hover:h-[465px] col-span-full rounded-xl bg-gradient-to-r from-gradientgold-100 via-gradientgold-90 to-gradientgold-80',
    packFreeCourse: 'text-2xl font-body font-semibold tracking-wider bg-gradient-to-b from-orange-950 to-alertwarning text-transparent',
    packWorkshop: 'text-2xl font-body font-semibold tracking-wider bg-gradient-to-b from-gradientsilver-100 to-secondary-10 text-transparent bg-clip-text',
    typeFreeCourse: 'mt-6 text-md sm:text-sm font-semibold tracking-wider font-bold bg-gradient-to-r from-gradientbronze-70 via-gradientbronze-60 to-gradientbronze-50 text-transparent bg-clip-text',
    typeWorkshop: 'mt-4 text-md sm:text-sm font-semibold tracking-wider font-bold bg-gradient-to-r from-gradientsilver-70 via-gradientsilver-60 to-gradientsilver-50 text-transparent bg-clip-text mb-4',
    typeBootcamp: 'mt-4 text-md sm:text-sm font-semibold tracking-wider font-bold bg-gradient-to-r from-gradientgold-70 via-gradientgold-60 to-gradientgold-50 text-transparent bg-clip-text mb-4',
    typeFreeCourseDescription: 'mt-2 text-md sm:text-sm font-semibold tracking-wider font-bold bg-gradient-to-r from-gradientbronze-70 via-gradientbronze-60 to-gradientbronze-50 text-transparent bg-clip-text mb-2 text-center',
    typeWorkshopDescription: 'text-md sm:text-sm font-semibold tracking-wider font-bold bg-gradient-to-r from-gradientsilver-70 via-gradientsilver-60 to-gradientsilver-50 text-transparent bg-clip-text mb-2 text-center',
    typeBootcampDescription: 'text-md sm:text-sm font-semibold tracking-wider font-bold bg-gradient-to-r from-gradientgold-70 via-gradientgold-60 to-gradientgold-50 text-transparent bg-clip-text mb-2 text-center',
    packageFreeCourseDescription: 'px-2 text-xs sm:text-xs font-semibold tracking-wider mb-4 font-bold bg-gradient-to-r from-gradientbronze-70 via-gradientbronze-60 to-gradientbronze-50 text-transparent bg-clip-text mb-2 text-center',
    packageWorkshopDescription: 'text-xs sm:text-xs font-semibold tracking-wider font-bold bg-gradient-to-r from-gradientsilver-70 via-gradientsilver-60 to-gradientsilver-50 text-transparent bg-clip-text mb-2 text-center',
    packageBootcampDescription: 'text-xs sm:text-xs font-semibold tracking-wider font-bold bg-gradient-to-r from-gradientgold-70 via-gradientgold-60 to-gradientgold-50 text-transparent bg-clip-text mb-2 text-center',
    packageFreeCourseSubdescription: 'text-xs sm:text-xs font-semibold tracking-wider font-bold bg-gradient-to-r from-gradientbronze-70 via-gradientbronze-60 to-gradientbronze-50 text-transparent bg-clip-text mb-2 text-center',
    packageWorkshopSubdescription: 'px-2 text-xs sm:text-xs font-semibold tracking-wider font-bold bg-gradient-to-r from-gradientsilver-70 via-gradientsilver-60 to-gradientsilver-50 text-transparent bg-clip-text mb-2 text-center',
    stackFreeCourse: 'mt-8 text-lg font-semibold tracking-wider font-bold bg-gradient-to-r from-gradientbronze-70 via-gradientbronze-60 to-gradientbronze-50 text-transparent bg-clip-text',
    stackWorkshop: 'text-lg font-semibold tracking-wider font-bold bg-gradient-to-r from-gradientsilver-70 via-gradientsilver-60 to-gradientsilver-50 text-transparent bg-clip-text mb-4',
    stackBootcamp: 'text-lg font-semibold tracking-wider font-bold bg-gradient-to-r from-gradientgold-70 via-gradientgold-60 to-gradientgold-50 text-transparent bg-clip-text mb-4 text-center',
    priceFreeCourse: 'my-8 text-2xl sm:text-2xl text-gradientbronze-90 font-bold tracking-wider font-bold',
    priceWorkshop: 'text-2xl sm:text-2xl text-gradientsilver-9 font-bold tracking-wider font-bold mb-4',
    priceBootcamp: 'text-2xl sm:text-2xl text-gradientgold-60 font-bold tracking-wider font-bold mb-4',
    buttonRegisterNowFreeCourse: 'hover:bg-gradientbronze-20 transition duration-300 ease-out w-full rounded-t rounded-b-lg bg-gradient-to-r from-gradientbronze-30 via-gradientbronze-20 to-gradientbronze-15 py-1.5',
    buttonRegisterNowWorkshop: 'hover:bg-gradientsilver-20 transition duration-300 ease-out w-full rounded-t rounded-b-lg bg-gradient-to-r from-gradientsilver-30 via-gradientsilver-20 to-gradientsilver-15 py-1.5',
    buttonRegisterNowBootcamp: 'hover:bg-gradientgold-20 transition duration-300 ease-out w-full rounded-t rounded-b-lg bg-gradient-to-r from-gradientgold-30 via-gradientgold-20 to-gradientgold-15 py-1.5',
    borderFreeCourse: 'absolute bottom-0 w-full rounded-t rounded-b-lg bg-gradient-to-r from-gradientbronze-30 via-gradientbronze-20 to-gradientbronze-15 pt-0.5',
    borderWorkshop: 'absolute bottom-0 w-full rounded-t rounded-b-lg bg-gradient-to-r from-gradientsilver-30 via-gradientsilver-20 to-gradientsilver-15 pt-0.5',
    borderBootcamp: 'absolute bottom-0 w-full rounded-t rounded-b-lg bg-gradient-to-r from-gradientgold-30 via-gradientgold-20 to-gradientgold-15 pt-0.5',
}

const containerClassStyle = (typeCourse: string) => {
    if (typeCourse === 'Workshop') {
        return classes.backgroundWorkshop
    } else if (typeCourse === 'Bootcamp') {
        return classes.backgroundBootcamp
    }

    return classes.backgroundFreeCourse
}

const typeCourseTextClassStyles = (typeCourse: string) => {
    if (typeCourse === 'Workshop') {
        return classes.typeWorkshop
    } else if (typeCourse === 'Bootcamp') {
        return classes.typeBootcamp
    }
    return classes.typeFreeCourse
}

const typeCourseDescriptionTextClassStyles = (typeCourse: string) => {
    if (typeCourse === 'Workshop') {
        return classes.typeWorkshopDescription
    } else if (typeCourse === 'Bootcamp') {
        return classes.typeBootcampDescription
    }
    return classes.typeFreeCourseDescription
}

const packageDescriptionTextClassStyles = (typeCourse: string) => {
    if (typeCourse === 'Workshop') {
        return classes.packageWorkshopDescription
    } else if (typeCourse === 'Bootcamp') {
        return classes.packageBootcampDescription
    }
    return classes.packageFreeCourseDescription
}

const stackTextClassStyles = (typeCourse: string) => {
    if (typeCourse === 'Workshop') {
        return classes.stackWorkshop
    } else if (typeCourse === 'Bootcamp') {
        return classes.stackBootcamp
    }
    return classes.stackFreeCourse
}
const packageSubdescriptionTextClassStyles = (typeCourse: string) => {
    if (typeCourse === 'Workshop') {
        return classes.packageWorkshopSubdescription
    }
    return classes.packageFreeCourseSubdescription
}

const totalPriceTextClassStyles = (typeCourse: string) => {
    if (typeCourse === 'Workshop') {
        return classes.priceWorkshop
    } else if (typeCourse === 'Bootcamp') {
        return classes.priceBootcamp
    }
    return classes.priceFreeCourse
}

const buttonBorderClassStyles = (typeCourse: string) => {
    if (typeCourse === 'Workshop') {
        return classes.borderWorkshop
    } else if (typeCourse === 'Bootcamp') {
        return classes.borderBootcamp
    }
    return classes.borderFreeCourse
}

const buttonRegisterClassStyles = (typeCourse: string) => {
    if (typeCourse === 'Workshop') {
        return classes.buttonRegisterNowWorkshop
    } else if (typeCourse === 'Bootcamp') {
        return classes.buttonRegisterNowBootcamp
    }
    return classes.buttonRegisterNowFreeCourse
}
