/**
 * BadgeCircleV2 Component
 * 
 * This component is render badge with various style based on data.TypeCourse property.
 * Display discount from the TypeCourse
 * 
 * This component have some of @component
 * @param {Object} props - Properties of this component
 * @param {string} className - Additional CSS styling to apply in the badge container
 * @param {number} size - The size of the badge
 * @param {ProgramTypeV2Properties} - Data in the const folder that is rendered on the badge 
 * @return the BadgeCircle Component
 */

import React from 'react';
import { ProgramTypeV2Properties } from '@/interfaces/ProgramType';

export const BadgeCircle: React.FunctionComponent<{
    className?: string;
    data: ProgramTypeV2Properties;
    size: number;
}> = (props) => {
    const { data } = props;

    const classNames = {
        borderWorkshop: 'absolute z-10 -right-3 -top-5 w-[90px] h-[90px] rounded-[45px] bg-gradient-to-r from-gradientsilver-7 via-gradientsilver-9 to-gradientsilver-7 flex items-center justify-center',
        backgroundWorkshop: 'text-center flex flex-col items-center justify-center bg-gradient-to-br from-gradientsilver-10 via-gradientsilver-9 to-gradientsilver-7 w-[82px] h-[82px] rounded-[41px]',
        borderFreeCourse: 'absolute z-10 -right-3 -top-5 w-[90px] h-[90px] rounded-[45px] bg-gradient-to-r from-gradientbronze-7 via-gradientbronze-9 to-gradientbronze-7 flex items-center justify-center',
        backgroundFreeCourse: 'text-center flex flex-col items-center justify-center bg-gradient-to-br from-gradientbronze-10 via-gradientbronze-9 to-gradientbronze-7 w-[82px] h-[82px] rounded-[41px]',
    };

    return (
        <div className={data.typeCourse === 'Workshop' ? classNames.borderWorkshop : classNames.borderFreeCourse}>
            <div className={data.typeCourse === 'Workshop' ? classNames.backgroundWorkshop : classNames.backgroundFreeCourse}>
                <p className='text-sm text-secondary-100 font-body font-bold'>Hemat</p>
                <p className='text-sm text-secondary-100 font-body font-bold'>{data.discountPrice}</p>
            </div>
        </div>
    );
};
