import React, { useState } from "react";
import { SectionContainerLight } from "../common/sections/SectionContainerLight";
import { HeaderSection } from "../common/headers/HeaderSection";
import { FreeCourse } from "@/components/schedule/FreeCourse";
import { Workshop } from "@/components/schedule/Workshop";
import { Bootcamp } from "../schedule/Bootcamp";

export const ScheduleProgramNew: React.FunctionComponent<{
  className?: string;
}> = () => {
  const [active, setActive] = useState("Free Course");

  const renderActiveContent = () => {
    switch (active) {
      case "Free Course":
        return <FreeCourse />;
      case "Workshop":
        return <Workshop />;
      case "Bootcamp":
        return <Bootcamp />;
      default:
        return <FreeCourse />;
    }
  };

  return (
    <SectionContainerLight id="program-schedule">
      <div className="px-5 md:px-16">
        <HeaderSection
          title="Jadwal Program"
          subtitle="Jadwal Program yang Bisa di Ikuti Peserta"
        />
      </div>
      <div className="container mx-auto px-10">
        <div className="bg-accent-100 p-6 rounded-2xl">
          <div className="bg-slate-800 rounded-xl">
            <div className="pt-12 px-8 m-2 flex flex-wrap justify-evenly text-2xl font-bold text-white" id="nav-tab" role="tablist">
              <button className={active === "Free Course" ? "underline underline-offset-8 decoration-accent-100 mb-4 my-4" : "hover:text-accent-100 mx-4 my-4"} onClick={() => setActive("Free Course")}>
                Free Course
              </button>
              <button className={active === "Workshop" ? "underline underline-offset-8 decoration-accent-100 mb-4 my-4" : "hover:text-accent-100 mx-4 my-4"} onClick={() => setActive("Workshop")} >
                Workshop
              </button>
              <button className={active === "Bootcamp" ? "underline underline-offset-8 decoration-accent-100 mb-4 my-4" : "hover:text-accent-100 mx-4 my-4"} onClick={() => setActive("Bootcamp")}>
                Bootcamp
              </button>
            </div>
            <div className="px-12 pt-8">{renderActiveContent()}</div>
          </div>
        </div>
      </div>
    </SectionContainerLight>
  );

}
  
