import React, { FC } from 'react'

interface CardWhyStudyInAccelistProps {
    title: string
    subtitle: string
    imageSrc: string
}

const CardWhyStudyInAccelist: FC<CardWhyStudyInAccelistProps> = ({ title, subtitle, imageSrc }) => {

    return (
        <div className='relative w-[299px] h-[262px] px-6 text-center mt-8 sm:mt-16'>
            <img src={imageSrc} alt="image.png" className='mx-auto h-24' />
            <h5 className='mt-5 text-accent-100 text-xl font-body font-bold'>{title}</h5>
            <p className='mt-4 sm:mt-7 text-main-100 text-sm font-body font-normal'>{subtitle}</p>
        </div>
    )
}

export default CardWhyStudyInAccelist