import React from 'react'
import { SectionContainerDark } from '../common/sections/SectionContainerDark'
import { HeaderSection } from '../common/headers/HeaderSection'
import CardWhyStudyInAccelist from '../common/card/CardWhyStudyInAccelist'

export const SectionWhyStudyInAccelist = () => {
    return (
        <SectionContainerDark id='why-study-in-accelist-indonesia'>
            <HeaderSection darkMode title='Mengapa Belajar di Accelist Edukasi Indonesia' subtitle='Alasan Kenapa Accelist Edukasi Indonesia Lebih Baik dari yang Lainnya' />
            <div className="flex flex-wrap px-8 md:px-16 pb-10">
                <div className='w-full flex flex-wrap  justify-center gap-x-6'>
                    <CardWhyStudyInAccelist title={'Berbasis Online'} imageSrc='img/home/project-based.png' subtitle={'Kami menggunakan sistem pembelajaran secara online atau daring melalui Zoom, LMS, dan platform lainnya.'} />
                    <CardWhyStudyInAccelist title={'Perluasan Koneksi'} imageSrc='img/home/qualified-instructor-team.png' subtitle={'Kami membantu memperluas koneksi antar sesama peserta dan perusahaan melalui pertemuan online.'} />
                    <CardWhyStudyInAccelist title={'Kerja Sama'} imageSrc='img/home/support-system.png' subtitle={'Kami menyediakan program pelatihan berbentuk kolaborasi antar peserta untuk mempersiapkan skill kolaborasi pada real project.'} />
                    <CardWhyStudyInAccelist title={'Perluasan Karier'} imageSrc='img/home/partners.png' subtitle={'Kami membantu perluasan jenjang karier para peserta melalui project magang perusahaan.'} />
                </div>
            </div>
        </SectionContainerDark>
    )
}
