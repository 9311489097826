import React, { FC } from "react";
interface DropdownPeer {
    question: string
    answer: string
}

export const DropdownFaq: FC<DropdownPeer> = ({ question, answer }) => {
    return (
        <div className="relative w-full overflow-hidden">
            <input type="checkbox" className="peer absolute top-0 inset-x-0 w-full h-full opacity-0 z-10 cursor-pointer" />
            <div className="bg-accent-100 h-28 md:h-16 w-full pl-14 pr-5 flex items-center">
                <h1 className="text-sm text-white font-body font-normal">{question}</h1>
            </div>
            <div className="absolute top-10 md:top-5 left-3 text-secondary-100 transition-transform duration-500 rotate-0 peer-checked:rotate-180">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"><path d="M18 15l-6-6-6 6" /></svg>
            </div>
            <div className="bg-white overflow-hidden transition-all duration-500 max-h-0 py-0 peer-checked:max-h-60 peer-checked:py-4">
                <div className="flex pl-14 pr-5 h-full items-center">
                    <p className="text-sm text-secondary-100 font-body font-normal">{answer}</p>
                </div>
            </div>
        </div>
    )
}