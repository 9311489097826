import React from "react";
import { SectionContainerDark } from "../common/sections/SectionContainerDark";
import { DropdownFaq } from "../common/dropdown/DropdownFaq";

export const SectionFaq = () => {
    return (
        <SectionContainerDark id="faq" >
            <div className="max-w-xl mx-auto text-center mb-16">
                <h1 className="text-white text-4xl font-body font-medium align-center">Frequently Asked Questions</h1>
            </div>
            <div className="max-w-4/5 mb-10 mx-10 md:mx-20">
                <DropdownFaq question="Berapa lama durasi setiap programnya?" answer="Lama durasi program free course 1 jam dan workshop 8 jam" />
                <DropdownFaq question="Apakah saya akan mendapatkan sertifikat setelah menyelesaikan kelas?" answer="Iya, kamu akan mendapatkan sertifikat keahlian dari Accelist yang telah terverifikasi DISNAKER" />
                <DropdownFaq question="Apa saja persyaratan untuk mengikuti program ini?" answer="Persyaratan yang diperlukan adalah memiliki laptop dan handphone (Kondisional)" />
                <DropdownFaq question="Dimana program ini dilaksanakan?" answer="Live 100% di Zoom" />
                
            </div>
        </SectionContainerDark>
    )
}