import React from 'react';
import { SectionContainerLight } from '../common/sections/SectionContainerLight';
import Link from 'next/link';
import { CircleLanding } from '../common/shape/CircleLanding';

export const SectionLanding: React.FunctionComponent<{
    className?: string;
}> = () => {

    return (
        <SectionContainerLight id='landing' >
            <div className="flex flex-wrap px-8 md:px-16">
                <div className="w-full self-center px-4 md:w-1/2">
                    <h1 className='text-2xl md:text-3xl lg:text-5xl text-secondary-100 font-body font-semibold tracking-wider leading-snug md:leading-relaxed mb-7 md:mb-10'>Belajar Mudah, Kapan Saja, Dimana Saja!</h1>
                    <p className='text-base md:text-lg lg:text-xl text-secondary-100 font-body font-medium mb-5 md:mb-7'>Belajar dengan mentor profesional berpengalaman lebih dari 10 tahun, real project dari perusahaan, live online class, dan masih banyak lagi. Lulusan terbaik akan mendapatkan kesempatan untuk berkarir bersama kami lho!</p>
                    <p className='text-base md:text-lg lg:text-xl text-secondary-100 font-body font-medium'>Masih ragu dengan program Accelist?</p>
                    <p className='text-base md:text-lg lg:text-xl text-secondary-100 font-body font-medium mb-7 md:mb-16'>Cek di sini ya!</p>
                    <Link href={'/#program-type'} className='text-sm sm:text-base font-medium font-body text-white bg-accent-100 py-3 px-4 sm:px-8 sm:py-4 rounded hover:bg-secondary-100 hover:text-white transition duration-300 ease-out'>
                        Lihat Program
                    </Link>
                </div>
                <div className='w-full self-center md:w-1/2'>
                    <div className='mt-10'>
                        <img src="img/landing-page.png" alt="landing-page.png" className='max-w-full mx-auto' />
                    </div>
                </div>
                <div className='w-full mt-16 sm:mt-32 items-center'>
                    <h2 className='text-center text-2xl md:text-3xl lg:text-4xl text-secondary-100 font-body font-bold tracking-wide'>Alumni yang Sudah Pernah Mengikuti Program Kami</h2>
                    <div className='flex flex-wrap px-8 md:px-16 mt-5 sm:mt-10 space-y-10 lg:space-y-0'>
                        <div className="flex flex-row w-full self-center px-4 md:w-full lg:w-1/2 justify-center space-x-14">
                            <CircleLanding numberData={'200+'} subtitle='Alumni' additionalClassName='' />
                            <CircleLanding numberData={'53'} subtitle={'Recruited by Accelist'} additionalClassName='' />
                        </div>
                        <div className='w-full self-end md:w-full lg:w-1/2'>
                            <div className='flex flex-col justify-center items-center w-full xl:w-3/4 h-32 md:h-40 sm:h-48 rounded-2xl bg-main-80 shadow-[3px_7px_10px_0px_#0000000f] px-8 md:px-14 lg:px-14 py-10'>
                                <p className='text-secondary-100 text-xs sm:text-base font-body font-bold'>200+ alumni sudah mengikuti program kami sejak tahun 2017, sekarang giliran kamu yang berkesempatan untuk menjadi lulusan berkualitas selanjutnya!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SectionContainerLight>
    );
};
