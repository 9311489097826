import React, { FC, PropsWithChildren } from "react";

interface SectionContainerGray {
    additionalClassName?: string
    id: string
}

export const SectionContainerGray: FC<PropsWithChildren<SectionContainerGray>> = ({ children, id, additionalClassName }) => {
    let className = 'py-20 bg-[#F2F2F2]'

    className += ` ${additionalClassName ?? ""}`;
    return (
        <section id={id} className={className}>
            {children}
        </section>
    )
}