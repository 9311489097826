import React from 'react'
import { SectionContainerLight } from '../common/sections/SectionContainerLight'
import { HeaderSection } from '../common/headers/HeaderSection'
import { CardRegistrationProcedur } from '../common/card/CardRegistrationProcedur'

export const SectionRegistrationProcedure = () => {
    return (
        <SectionContainerLight id='registration-procedure'>
            <HeaderSection title='Prosedur Pendaftaran' subtitle='Berikut Merupakan Prosedur Pendaftar' />
            <div className="flex flex-wrap px-8 md:px-16 pb-10">
                <div className='w-full flex flex-wrap  justify-center gap-x-6'>
                    {/* Because title and description in this section not used in new UI  */}

                    {/* <CardRegistrationProcedur order='1' title='Login / Sign Up' description='Jika belum memiliki akun, peserta perlu melakukan pembuatan akun. Jika sudah memiliki akun, peserta bisa langsung login' />
                    <CardRegistrationProcedur order='2' title='Bayar' description='Peserta perlu melakukan pembayaran untuk mengikuti program yang diinginkan. Transfer via ke bank terkait' />
                    <CardRegistrationProcedur order='3' title='Melengkapi Administrasi' description='Peserta perlu melengkapi administrasi dengan mengupload berkas yang dibutuhkan. untuk memastikan sudah memenuhi persyaratan' />
                    <CardRegistrationProcedur order='4' title='Pengecekan Kelengkapan' description='Tim dari Accelist Edukasi Indonesia akan melakukan pengecekan kelengkapan dokumen dan informasi yang sudah diunggah oleh peserta' /> */}
                    
                    <CardRegistrationProcedur order='1' title='Login / Sign Up' description='Jika belum memiliki akun, peserta perlu melakukan pembuatan akun atau sign up. Jika sudah memiliki akun, peserta bisa langsung login' />
                    <CardRegistrationProcedur order="2" title="Pilih Program" description="Peserta wajib memilih program dan menuju formulir pendaftaran dengan klik 'DAFTAR SEKARANG'" />
                    <CardRegistrationProcedur order='3' title='Isi dan Kirim Formulir Pendaftaran' description='Peserta wajib melengkapi data diri dan pilihan program. Jangan lupa klik kirim pendaftaran di bagian paling bawah formulir' />
                    <CardRegistrationProcedur order='4' title='Tunggu dihubungi oleh panitia' description='Setelah itu, cek Email dan WhatsApp kamu secara berkala untuk mengetahui jalannya program' />
                </div>
            </div>
        </SectionContainerLight>
    )
}